import Link from '@react/widget/Link';
import clsx from 'clsx';

interface Props extends Record<string, any> {
  currentSite: Page.Site;
  data?: Page.Navigation;
}

const FooterCommon: React.FC<Props> = ({ currentSite, data, ...props }) => {
  if (!data)
    return null;
  return (
    <dl {...props}>
      <dt className="border-split-light border-solid border-b py-3 font-medium">
        {data.title === "Confidence Platform" ? <a href={`${currentSite.localePath}/confidence-platform`}>{data.title}</a> : data.title}
      </dt>
      {data.children?.map((_nav, index, _a) => (
        <dd key={`footer-common-${index}`} className={clsx("text-sm border-split-light border-solid", !!index && "border-t")}>
          {!!_a.length && <span className="block pt-2.5 font-medium tracking-wide">{_nav.title}</span>}
          <ul className="py-2.5 grid gap-y-2.5">
            {_nav.children?.map((_item, _index) => (
              <li key={`footer-common-${index}-li-${_index}`}>
                <Link href={ _item.uri.indexOf("http") !== -1 ? `${_item.uri}` : `${currentSite.localePath}/${_item.uri}`}>{_item.title}</Link>
              </li>
            ))}
          </ul>
        </dd>
      ))}
      {(data.navigationType === "about" && currentSite.pathname === '/lp/zero-trust') && (
        <dd id="zero-trust">
          <div className='space-y-4'>
            <div className='border-split-light border-solid border-b py-3 font-medium'>Proud Partner</div>
            <a className='block' href='https://www.nba.com/wizards' target='_blank'>
              <img src='https://assets.avepoint.com/upload/images/solutions/images/landing-page/illustration/washington-wizards.svg' alt='trust in acrobatism' width={120} height={120}/>
            </a>
          </div>
        </dd>
      )}
    </dl>
  );
};

export default FooterCommon;